import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import Paragraph from "../../components/Typography/Paragraph";

import { Container, Grid, Box } from "@mui/material";

import Button from "../../components/Button/Button";

export default function ShowRoom() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="2rem 0">
          <Paragraph
            color="#2a2866"
            bold="700"
            size="24px"
            margin="0 0 2rem 0"
            center
          >
            OUR SHOWROOM
          </Paragraph>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Paragraph
                  color="#2a2866"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  GILDAN HQ
                </Paragraph>
                <Box sx={{ border: "1px solid #CCC", padding: "20px 25px" }}>
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    ADDRESS:
                  </Paragraph>
                  <Paragraph>
                    2, Jalan Wawasan 3, Kawasan Perindustrian Sri Gading, 83300
                    Batu Pahat, Johor, Malaysia
                  </Paragraph>
                  <br />
                  {/* <Paragraph bold="700" mmargin="0 0 1rem 0">
                    OPERATING HOURS:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Mon to Fri</td>
                        <td>:8:30am - 6:00pm</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="2">
                          Closed on Weekend &amp; Public Holidays
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> */}
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    CONTACT:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Tel</td>
                        <td>:+6016-2961553</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <a href="https://wa.me/+60162961553" target="_blank">
                    <Button color="#FFF" bg="#125396" label="WHATAPPS US" />
                  </a>
                  <br />
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/3zSJ58ktu1m5ryMe8"
                  >
                    <Button color="#FFF" bg="#125396" label="GET LOCATION" />
                  </a>
                </Box>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Paragraph
              color="#2a2866"
              bold="700"
              size="24px"
              margin="0 0 1rem 0"
            >
              GILDAN BRANCHES
            </Paragraph>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Box sx={{ border: "1px solid #CCC", padding: "20px 25px" }}>
                  <Paragraph
                    color="#2a2866"
                    bold="700"
                    size="18px"
                    margin="0 0 1rem 0"
                  >
                    GILDAN BATU PAHAT
                  </Paragraph>
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    ADDRESS:
                  </Paragraph>
                  <Paragraph>
                    2, Jalan Wawasan 3, Kawasan Perindustrian Sri Gading, 83300
                    Batu Pahat, Johor, Malaysia
                  </Paragraph>
                  <br />
                  {/* <Paragraph bold="700" mmargin="0 0 1rem 0">
                    OPERATING HOURS:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Mon to Fri</td>
                        <td>:8:30am - 6:00pm</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="2">
                          Closed on Weekend &amp; Public Holidays
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> */}
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    CONTACT:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Tel</td>
                        <td>:+6012-2361553</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <a href="https://wa.me/+60122361553" target="_blank">
                    <Button color="#FFF" bg="#125396" label="WHATAPPS US" />
                  </a>
                  <br />
                  <a href="https://maps.app.goo.gl/PYS8oERJJtMx6CZV6">
                    <Button color="#FFF" bg="#125396" label="GET LOCATION" />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box sx={{ border: "1px solid #CCC", padding: "20px 25px" }}>
                  <Paragraph
                    color="#2a2866"
                    bold="700"
                    size="18px"
                    margin="0 0 1rem 0"
                  >
                    GILDAN PETALING JAYA
                  </Paragraph>
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    ADDRESS:
                  </Paragraph>
                  <Paragraph>
                    131, Jalan SS 25/2, Taman Mayang, 47301 Petaling Jaya,
                    Selangor, Malaysia
                  </Paragraph>
                  <br />
                  {/* <Paragraph bold="700" mmargin="0 0 1rem 0">
                    OPERATING HOURS:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Mon to Fri</td>
                        <td>:8:30am - 6:00pm</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="2">
                          Closed on Weekend &amp; Public Holidays
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> */}
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    CONTACT:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Tel</td>
                        <td>:+6016710-1553</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <a href="https://wa.me/+60167101553" target="_blank">
                    <Button color="#FFF" bg="#125396" label="WHATAPPS US" />
                  </a>
                  <br />
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/8Mi4PEVFTbGhw9Hn7"
                  >
                    <Button color="#FFF" bg="#125396" label="GET LOCATION" />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box sx={{ border: "1px solid #CCC", padding: "20px 25px" }}>
                  <Paragraph
                    color="#2a2866"
                    bold="700"
                    size="18px"
                    margin="0 0 1rem 0"
                  >
                    GILDAN PANDAN INDAH (KL)
                  </Paragraph>
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    ADDRESS:
                  </Paragraph>
                  <Paragraph>
                    24G, Jalan Pandan Indah 1/23A, Pandan Indah, 55100 Kuala
                    Lumpur, Malaysia
                  </Paragraph>
                  <br />
                  {/* <Paragraph bold="700" mmargin="0 0 1rem 0">
                    OPERATING HOURS:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Mon to Fri</td>
                        <td>:8:30am - 6:00pm</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="2">
                          Closed on Weekend &amp; Public Holidays
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> */}
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    CONTACT:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Tel</td>
                        <td>:+6016-7101553</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <a href="https://wa.me/+60167101553" target="_blank">
                    <Button color="#FFF" bg="#125396" label="WHATAPPS US" />
                  </a>
                  <br />
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/ro4h9K49h9NGemVP8"
                  >
                    <Button color="#FFF" bg="#125396" label="GET LOCATION" />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box sx={{ border: "1px solid #CCC", padding: "20px 25px" }}>
                  <Paragraph
                    color="#2a2866"
                    bold="700"
                    size="18px"
                    margin="0 0 1rem 0"
                  >
                    GILDAN SINGAPORE
                  </Paragraph>
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    ADDRESS:
                  </Paragraph>
                  <Paragraph>
                    Blk. 12, Lorong Bakar Batu , Unit 03-04 , Singapore 348745
                  </Paragraph>
                  <br />
                  {/* <Paragraph bold="700" mmargin="0 0 1rem 0">
                    OPERATING HOURS:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Mon to Fri</td>
                        <td>:8:30am - 6:00pm</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="2">
                          Closed on Weekend &amp; Public Holidays
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> */}
                  <Paragraph bold="700" mmargin="0 0 1rem 0">
                    CONTACT:
                  </Paragraph>
                  <table id="oh">
                    <tbody>
                      <tr>
                        <td>Tel</td>
                        <td>:+65 9855 3046</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <a href="https://wa.me/+6598553046" target="_blank">
                    <Button color="#FFF" bg="#125396" label="WHATAPPS US" />
                  </a>
                  <br />
                  <a
                    href="https://maps.app.goo.gl/iTUUPrXwUXcQSiNQA"
                    target="_blank"
                  >
                    <Button color="#FFF" bg="#125396" label="GET LOCATION" />
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
