import React from "react";
import Slider from "react-slick";
import useStyles, {
  IncrementAction,
  IncrementAction2,
  FormInputSelect,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import silestone001 from "../../assets/images/silestone001.png";
import purchase from "../../assets/images/purchase.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import { useDropzone } from "react-dropzone";

import {
  Container,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
} from "@mui/material";
import {
  Add,
  Close,
  InsertDriveFile,
  InsertPhoto,
  Remove,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import {
  add_address,
  checkout,
  get_address,
  get_country,
  get_customer_cart,
  get_customer_profile,
  get_default_billing_address,
  get_shipping_carrier_by_id,
  get_shipping_method,
  get_state,
  get_upload_consignment_note,
  get_upload_file,
  upload_attachment,
  validate_cart,
  validate_state,
} from "../../API/API";
import useCart from "../../hooks/useCart";
import "./styles.css";
import Card from "../../components/Card/Card";
import { json, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

export default function Payment() {
  const classes = useStyles();
  const navigate = useNavigate();

  // CART LIST

  const {
    getCart,
    decreaseProduct,
    increaseProduct,
    removeProduct,
    getTotalCart,
    clearCart,
  } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart());

  const productCartList = getcart.replace(/\\/g, "");

  const [qtyCount, setQtyCount] = React.useState(0);

  React.useEffect(() => {
    setCart(getCart());
  }, [qtyCount]);

  const [newCartist, setNewCartList] = React.useState([]);
  React.useEffect(() => {
    let a = window.localStorage.getItem("cart");
    setNewCartList(JSON.parse(a));
  }, [window.localStorage.getItem("cart")]);

  const [chooseAddress, setChooseAddress] = React.useState("");

  const [currentData, setCurrentData] = React.useState([]);
  const [finalAmount, setFinalAmount] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState([]);

  const [showReceipt, setShowReceipt] = React.useState(0);

  const [deliveryOption, setDeliveryOption] = React.useState(1);

  const handleChangeDeliveryOption = (event) => {
    setDeliveryOption(event.target.value);
  };

  // API GET ADDRESS LIST
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [checkCountry, setCheckCountry] = React.useState("");
  const [customerContactID, setCustomerContactID] = React.useState(0);
  const [customerDropShip, setCustomerDropShip] = React.useState();
  const [checkCurrency, setCheckCurrency] = React.useState("");

  const [uploadPDF, setUploadPDF] = React.useState();
  const [uploadAttachment, setUploadAttachment] = React.useState();

  const [shippingFee, setShippingFee] = React.useState(0);
  const [handlingFee, setHandlingFee] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [sst, setSST] = React.useState(0);
  const [gst, setGST] = React.useState(0);

  const getUser = () => {
    return;
    get_customer_profile({ token: token }).then((json) => {
      setCheckCountry(json.data.subsidiary);
      setCustomerContactID(json.data.customer_contact_id);
      setCustomerDropShip(json.data.dropship);
      setCheckCurrency(json.data.currency);
    });
  };

  React.useEffect(() => {
    getUser();
  }, [customerContactID]);

  const [stockAva, setStockAva] = React.useState([]);

  // React.useEffect(() => {
  //   let _storageCart = getCart();

  //   const updatedItems = _storageCart.map((item) => {
  //     const { name, ...rest } = item;
  //     return rest;
  //   });

  //   if (chooseAddress == 0 || chooseAddress == "") {
  //     return;
  //   }

  //   let postparam = {
  //     ...uploadPDF,
  //     item: JSON.stringify(updatedItems),
  //     customer_address_id: chooseShippingAddress,
  //     billing_address_id: chooseAddress,
  //     shipping_method_id: chooseShippingMethod,
  //     token: token,
  //   };

  //   validate_cart(postparam).then((res) => {
  //     if (res.status) {
  //       // setCurrentData(res.data);
  //       // console.log("RES",res);
  //       setFinalAmount(res.final_amount);
  //       setTotalAmount(res.total_amount);
  //       setShippingFee(res.shipping_fee);
  //       setHandlingFee(res.handling_fee);
  //       setDiscountAmount(res.discountamount);
  //       setGST(res.gst);
  //       setSST(res.sst);
  //       setStockAva([]);
  //     } else {
  //       alert("Please Check Stock Available");
  //       setStockAva(res.out_of_stock);
  //     }
  //   });
  // }, []);

  const calculateCart = async () => {
    // let _storageCart = getCart();

    // const updatedItems = _storageCart.map((item) => {
    //   const { name, ...rest } = item;
    //   return rest;
    // });

    let _cart = window.localStorage.getItem("cart");

    if (chooseAddress == 0 || chooseAddress == "") {
      return;
    }

    let postparam = {
      ...uploadPDF,
      item: _cart,
      customer_address_id: chooseShippingAddress,
      billing_address_id: chooseAddress,
      shipping_method_id: chooseShippingMethod,
      token: token,
    };

    var res = await validate_cart(postparam);
    if (res.status) {
      setFinalAmount(res.final_amount);
      setTotalAmount(res.total_amount);
      setShippingFee(res.shipping_fee);
      setHandlingFee(res.handling_fee);
      setDiscountAmount(res.discountamount);
      setGST(res.gst);
      setSST(res.sst);
      setStockAva([]);
    } else {
      alert("Please Check Stock Available");
      setStockAva(res.out_of_stock);
    }
  };

  const [addressList, setAddressList] = React.useState([]);
  const [defaultBillingAddrList, setDefaultBillingAddrList] = React.useState(
    {}
  );

  const [country, setCountry] = React.useState([]);
  const [chooseCountry, setChooseCountry] = React.useState("");

  const getCountry = () => {
    get_country({ token: token }).then((json) => {
      setCountry(json.data);
    });
  };

  const handleChooseCountry = (e) => {
    setChooseCountry(e.target.value);
  };

  const [state, setState] = React.useState([]);
  const [chooseState, setChooseState] = React.useState("");

  const getState = () => {
    get_state({ token: token }).then((json) => {
      setState(json.data);
    });
  };

  const handleChooseState = (e) => {
    setChooseState(e.target.value);
  };

  const [zipCode, setZipCode] = React.useState("");

  // CHOOSE SHIPPING AND BILLING ADDRESS

  const [chooseBillingAddress, setChooseBillingAddress] = React.useState(0);
  const [chooseShippingAddress, setChooseShippingAddress] = React.useState(0);

  const handleChooseBillingAddress = (customer_address_id) => {
    setChooseBillingAddress(customer_address_id);

    // console.log("BILLING ADDRESS", customer_address_id);
  };

  const handleChooseShippingAddress = (e) => {
    setChooseShippingAddress(e.target.value);
    // console.log("SHIPPING ADDRESS", e.target.value);
    // calculateCart();
    let _storageCart = getCart();

    const updatedItems = _storageCart.map((item) => {
      const { name, ...rest } = item; // Destructure the name field from the item
      return rest; // Return the modified object without the name field
    });

    let postparam = {
      ...uploadPDF,
      item: JSON.stringify(updatedItems),
      customer_address_id: e.target.value,
      billing_address_id: chooseAddress,
      shipping_method_id: chooseShippingMethod,
      token: token,
    };

    let _cart = window.localStorage.getItem("cart");
    setNewCartList(JSON.parse(_cart));

    validate_cart(postparam).then((res) => {
      if (res.status) {
        setFinalAmount(res.final_amount);
        setTotalAmount(res.total_amount);
        setShippingFee(res.shipping_fee);
        setHandlingFee(res.handling_fee);
        setDiscountAmount(res.discountamount);
        setGST(res.gst);
        setSST(res.sst);
      } else {
        // alert("Please Check Stock Available");
        alert(res.message);
      }
    });
  };

  // CHOOSE SHIPPING METHOD

  const [shippingMethod, setShippingMethod] = React.useState([]);
  const [shippingCarrier, setShippingCarrier] = React.useState([]);
  const [carrier, setCarrier] = React.useState([]);

  const [chooseShippingMethod, setChooseShippingMethod] = React.useState(0);
  const [chooseCarrier, setChooseCarrier] = React.useState("");

  const handleChooseShippingMethod = (e) => {
    setChooseShippingMethod(e.target.value);
    let _storageCart = getCart();

    const updatedItems = _storageCart.map((item) => {
      const { name, ...rest } = item; // Destructure the name field from the item
      return rest; // Return the modified object without the name field
    });

    let postparam = {
      ...uploadPDF,
      item: JSON.stringify(updatedItems),
      customer_address_id: chooseShippingAddress,
      billing_address_id: chooseAddress,
      shipping_method_id: e.target.value,
      token: token,
    };

    let _cart = window.localStorage.getItem("cart");
    setNewCartList(JSON.parse(_cart));

    validate_cart(postparam).then((res) => {
      if (res.status) {
        setFinalAmount(res.final_amount);
        setTotalAmount(res.total_amount);
        setShippingFee(res.shipping_fee);
        setHandlingFee(res.handling_fee);
        setDiscountAmount(res.discountamount);
        setGST(res.gst);
        setSST(res.sst);
      } else {
        alert(res.message);
      }
    });

    get_shipping_carrier_by_id({
      shipping_method_id: e.target.value,
    }).then((json) => {
      setShippingCarrier(json.data);
    });

    // let postparam = {
    //   ...uploadPDF,
    //   item: JSON.stringify(newCartist),
    //   customer_address_id: chooseShippingAddress,
    //   billing_address_id: chooseBillingAddress,
    //   shipping_method_id: e.target.value,
    //   token: token,
    // };

    // console.log("validate cart : handleChooseSHipping");
    // validate_cart(postparam).then((res) => {
    //   if (res.status) {
    //     setFinalAmount(res.final_amount);
    //     setTotalAmount(res.total_amount);
    //     setShippingFee(res.shipping_fee);
    //     setHandlingFee(res.handling_fee);
    //     setDiscountAmount(res.discountamount);
    //     setGST(res.gst);
    //     setSST(res.sst);
    //   }
    // });
  };

  const handleChooseCarrier = (e) => {
    setChooseCarrier(e.target.value);
  };

  const getShippingMethod = () => {
    setLoading(true);
    get_shipping_method({ token: token }).then((json) => {
      setShippingMethod(json.data);
      setLoading(false);
    });
  };

  const getDefaultAddrList = () => {
    setLoading(true);
    get_default_billing_address({ token: token }).then((json) => {
      setDefaultBillingAddrList(json.data);

      if (json.status) {
        // console.log("DEFAULT ADDRESS", json.data);
        setChooseAddress(json.data.customer_address_id);
      }
      setLoading(false);
    });
  };

  const getAddressBook = async () => {
    // get_address({ token: token }).then((json) => {
    //   setAddressList(json.data);
    // });
    setLoading(true);
    var res = await get_address({ token: token });
    if (res.status) {
      setAddressList(res.data);

      if (res.data.defaultbilling == 1) {
        setDefaultBillingAddress(true);
        setChecked(true);
      } else {
        setDefaultBillingAddress(false);
        setChecked(false);
      }

      if (res.data.defaultshipping == 1) {
        setDefaultShippingAddress(true);
        // setChooseShippingAddress(res.data.customer_address_id)
        setChecked2(true);
      } else {
        setDefaultShippingAddress(false);
        setChecked2(false);
      }

      setLoading(false);
    }
  };

  // const calculateCart = () => {
  //   let postparam = {
  //     item: productCartList,
  //   };
  //   validate_cart(postparam).then((json) => {
  //     if (json.status) {
  //       setShippingFee(json.shipping_fee);
  //     } else {
  //       alert("Please Check Stock Available");
  //     }
  //   });
  // };

  // console.log(currentDefaultAddress);

  const getCustomerProfileAndCart = () => {
    get_customer_profile({ token: token }).then((json) => {
      setCheckCountry(json.data.subsidiary);
      setCustomerContactID(json.data.customer_contact_id);
      setCustomerDropShip(json.data.dropship);
      // console.log(json.data.customer_contact_id);
      setCheckCurrency(json.data.currency);

      get_customer_cart({
        customer_contact_id: json.data.customer_contact_id,
        token: token,
      }).then((json) => {
        setCurrentData(json.data);
        window.localStorage.setItem("cart", JSON.stringify(json.data));

        // console.log(json.data);

        let postparam = {
          ...uploadPDF,
          item: JSON.stringify(json.data),
          customer_address_id: chooseShippingAddress,
          billing_address_id: chooseBillingAddress,
          shipping_method_id: chooseShippingMethod,
          token: token,
        };

        // console.log("validate cart : get customer profile");
        // validate_cart(postparam).then((res) => {
        //   if (res.status) {
        //     // setCurrentData(res.data);
        //     setFinalAmount(res.final_amount);
        //     setTotalAmount(res.total_amount);
        //     setShippingFee(res.shipping_fee);
        //     setHandlingFee(res.handling_fee);
        //     setDiscountAmount(res.discountamount);
        //     setGST(res.gst);
        //     setSST(res.sst);
        //   } else {
        //     // alert("Please Check Stock Available");
        //     alert(res.message);
        //   }
        // });
      });
    });

    get_shipping_method({ token: token }).then((json) => {
      setShippingMethod(json.data);

      if (customerDropShip == 1) {
        setChooseShippingMethod(
          json.data.length > 0 ? json.data[0].shipping_method_id : 0
        );
      }

      get_shipping_carrier_by_id({
        shipping_method_id:
          json.data.length > 0 ? json.data[0].shipping_method_id : 0,
      }).then((json) => {
        setShippingCarrier(json.data);
        //HERE
        calculateCart();
      });
      // calculateCart();
    });

    get_address({ token: token }).then((json) => {
      setAddressList(json.data);

      for (var i = 0; i < json.data.length; i++) {
        if (json.data[i].defaultshipping == 1) {
          setChooseShippingAddress(json.data[i].customer_address_id);
        }
        if (json.data[i].defaultbilling == 1) {
          setChooseBillingAddress(json.data[i].customer_address_id);
        }
      }
    });

    if (customerDropShip == 1) {
      setChooseShippingMethod("13");
    }
  };

  // console.log(
  //   "SHIPPING METHOD ID: ",
  //   chooseShippingMethod,
  //   " SHIPPING ADDRESS ID: ",
  //   chooseShippingAddress
  // );

  React.useEffect(
    () => {
      // console.log("TEST USEEFFECT");
      getCustomerProfileAndCart();
    },
    [
      // qtyCount,
      // totalAmount,
      // chooseShippingAddress,
      // chooseBillingAddress,
      // chooseShippingMethod,
      // customerDropShip,
      // uploadPDF,
    ]
  );

  React.useEffect(() => {
    // calculateCart();
    // getAddressBook();
    // console.log("USE EFFECT TEST");
    getShippingMethod();
    getDefaultAddrList();
    getCountry();
    getState();
    // handleChooseShippingAddress();
  }, []);

  //DropZone

  const dropzone = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    marginBottom: ".6rem",
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    // width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const [files, setFiles] = React.useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    // accept: "image/jpeg, image/png",
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles);
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        // const file = Array.from(acceptedFiles);

        var index = 0;

        var formData = new FormData();

        // for (let i = 0; i < file.length; i++) {
        //   formData.append("image[]", file[i]);
        //   index = i;
        // }

        formData.append("image", file);

        var res = await get_upload_file(formData);

        if (res.data.status) {
          setUploadPDF({
            file: res.data.data,
          });
          //HERE
          calculateCart();
        } else {
          alert(res.data.message);
        }
      } else {
        alert("Please Upload One Consignment Note Only");
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, index) => (
    <div
      style={{
        background: "#eaeaea",
        borderRadius: "4px",
        padding: "1rem",
        marginBottom: "1rem",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div key={file.name} style={{ display: "flex", alignItems: "center" }}>
        {file.type == "application/pdf" && (
          <InsertDriveFile sx={{ marginRight: "12px", color: "#F90000" }} />
        )}
        {file.type == "image/png" && (
          <InsertPhoto sx={{ marginRight: "12px", color: "#00b5a2" }} />
        )}

        {file.type == "image/jpeg" && (
          <InsertPhoto sx={{ marginRight: "12px", color: "#00b5a2" }} />
        )}

        <p style={{ margin: 0 }}>{file.name}</p>
      </div>
      <Close
        sx={{ color: "#F90000", zIndex: 1 }}
        onClick={() => handleDelete(index)}
      />
    </div>
  ));

  const handleDelete = async (fileIndex) => {
    const updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
    setUploadPDF({
      file: "",
    });
    //HERE
    calculateCart();
  };

  //

  const handleChangeUpload = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);

    var res = await get_upload_consignment_note(formData);
    if (res.data.status) {
      setUploadPDF({
        file: res.data.data,
      });
      //HERE
      calculateCart();
    } else {
      alert(res.data.message);
    }
  };

  const handleChangeUpload2 = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);

    var res = await get_upload_file(formData);
    if (res) {
      setUploadAttachment({
        ...uploadAttachment,
        attachment: res.data.data,
      });
    }
  };

  const [addressData, setAddressData] = React.useState({
    // name: "",
    contact_name: "",
    contact_number: "",
    addr1: "",
    addr2: "",
    state: chooseState,
    country: chooseCountry,
    // phone: "",
    city: "",
  });

  const [customerRemark, setCustomerRemark] = React.useState({
    customer_remark: "",
    polybag: false
  });

  const [custData, setCustData] = React.useState({
    contact_name: "",
    contact: "",
  });

  // const [customerMemo, setCustomerMemo] = React.useState({
  //   memo: "",
  // });

  const handleChange = (e) => {
    if (e.target.name == 'polybag') {
      setCustomerRemark({
        ...customerRemark,
        polybag: e.target.checked
      });
    } else {
      setAddressData({
        ...addressData,
        [e.target.name]: e.target.value,
      });
      setCustomerRemark({
        ...customerRemark,
        [e.target.name]: e.target.value,
      });
      setCustData({
        ...custData,
        [e.target.name]: e.target.value,
      });
    }

    // setCustomerMemo({
    //   ...customerMemo,
    //   [e.target.name]: e.target.value,
    // });
  };

  const handleChangeZip = (e) => {
    setZipCode(e.target.value);
  };

  const handleChangeAddValid = (event) => {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputValue.length > 40) {
      alert(`${inputName} should not exceed 40 characters.`);
      inputValue = inputValue.slice(0, 40);
      event.target.value = inputValue;
    }

    setAddressData((prevData) => ({
      ...prevData,
      [inputName]: inputValue,
    }));
  };

  const [checkValidate, setCheckValidate] = React.useState(true);

  const getValidate = () => {
    validate_state({ zip: zipCode, state: chooseState }).then((json) => {
      if (json.status) {
        setCheckValidate(json.status);
      } else {
        setCheckValidate(json.status);
      }
    });
  };

  React.useEffect(() => {
    calculateCart();
  }, [
    chooseAddress,
    chooseBillingAddress,
    // chooseShippingMethod,
    // chooseShippingAddress,
    // shippingCarrier,
    // uploadPDF,
    // chooseState,
    // zipCode,
  ]);
  // ADD NEW ADDRESS BOOK

  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const [defaultBillingAddress, setDefaultBillingAddress] = React.useState("");
  const [defaultShippingAddress, setDefaultShippingAddress] =
    React.useState("");

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

    // console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultBillingAddress("1");
    }

    if (event.target.checked == false) {
      setDefaultBillingAddress("0");
    }
  };

  const handleChangeCheck2 = (event) => {
    setChecked2(event.target.checked);

    // console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultShippingAddress("1");
    }

    if (event.target.checked == false) {
      setDefaultShippingAddress("0");
    }
  };

  const handleAddAddress = () => {
    if (!addressData.addr1 || !addressData.addr2 || !addressData.city) {
      alert("All fields are required");
      return;
    }

    if (!zipCode || !chooseState || !chooseCountry) {
      alert("All fields are required");
      return;
    }

    validate_state({ zip: zipCode, state: chooseState }).then((json) => {
      setCheckValidate(json.status);

      add_address({
        ...addressData,
        defaultbilling: 0,
        defaultshipping: defaultShippingAddress,
        token: token,
        country: chooseCountry,
        state: chooseState,
        zip: zipCode,
      }).then((json) => {
        if (json.status && checkValidate == true) {
          alert("Submitted Successfully");
          setChooseCountry("");
          setChooseState("");
          setZipCode("");
          setOpenModal(false);
        }

        // setAddressList(
        //   json.data.sort(
        //     (a, b) => b.customer_address_id - a.customer_address_id
        //   )
        // );

        // setChooseShippingAddress(json.data[0].customer_address_id);

        // for (var i = 0; i < json.data.length; i++) {
        //   if (json.data[i].defaultbilling == 1) {
        //     setChooseBillingAddress(json.data[i].customer_address_id);
        //   }
        // }

        get_address({ token: token }).then((json) => {
          setAddressList(
            json.data.sort(
              (a, b) => b.customer_address_id - a.customer_address_id
            )
          );

          setChooseShippingAddress(json.data[0].customer_address_id);

          for (var i = 0; i < json.data.length; i++) {
            if (json.data[i].defaultbilling == 1) {
              setChooseBillingAddress(json.data[i].customer_address_id);
            }
          }
        });

        if (customerDropShip == 1) {
          setChooseShippingMethod("13");
        }

        setChooseCountry("");
        setChooseState("");
        setZipCode("");
        setOpenModal(false);
      });
    });
  };

  //  CHECKOUT

  const [disabled, setDisabled] = React.useState(false);

  const handleCheckout = () => {
    if (newCartist.length === 0) {
      alert("Your Cart is Empty. Please Add Items Before Checking Out.");
      return;
    }

    if (shippingCarrier.length > 0) {
      setDisabled(true);
      if (chooseBillingAddress == "") {
        setDisabled(false);
        alert("Please Choose Your Billling Address");
        return;
      }
      if (chooseShippingAddress == "") {
        setDisabled(false);
        alert("Please Choose Your Shipping Address");
        return;
      }

      if (chooseShippingMethod == 0) {
        setDisabled(true);
        alert("Please Choose Your Shipping Method");
        return;
      }

      if (chooseCarrier == "") {
        setDisabled(false);
        alert("Please Choose Your Shipping Carrier");
        return;
      }

      if (token == null || undefined || "") {
        setDisabled(false);
        navigate("/login");
      } else {
        const updatedItems = newCartist.map((item) => {
          const { name, ...rest } = item; // Destructure the name field from the item
          return rest; // Return the modified object without the name field
        });

        let postparam = {
          ...uploadPDF,
          ...uploadAttachment,
          ...customerRemark,
          // ...custData,
          // ...customerMemo,
          item: JSON.stringify(updatedItems),
          customer_address_id: chooseShippingAddress,
          billing_address_id: chooseBillingAddress,
          shipping_method_id: chooseShippingMethod,
          shipping_carrier_id: chooseCarrier,
          total_amount: parseFloat(finalAmount).toFixed(2),
        };
        // console.log(postparam);
        checkout(postparam).then((json) => {
          if (stockAva.length > 0) {
            alert("Please Check Stock Available");
          } else {
            if (checkCurrency == "SGD") {
              if (json.status == true && json.jespa != "") {
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
                window.open(
                  "https://megahtex.com/paynow/" + json.jespa,
                  "_blank"
                );
              } else {
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
                setDisabled(false);
              }
            } else {
              if (json.status == false) {
                alert(json.message, json.data);
                setDisabled(false);
              }

              if (json.status == true && json.data != "" && json.razer == "") {
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
              }

              if (json.status == true && json.data != "" && json.razer != "") {
                window.open(json.razer);
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
              }
            }
          }
        });
      }
    } else {
      setDisabled(true);
      if (chooseBillingAddress == "") {
        setDisabled(false);
        alert("Please Choose Your Billling Address");
        return;
      }
      if (chooseShippingAddress == "") {
        setDisabled(false);
        alert("Please Choose Your Shipping Address");
        return;
      }

      if (chooseShippingMethod == 0) {
        setDisabled(false);
        alert("Please Choose Your Shipping Method");
        return;
      }

      if (token == null || undefined || "") {
        setDisabled(false);
        navigate("/login");
      } else {
        const updatedItems = newCartist.map((item) => {
          const { name, ...rest } = item; // Destructure the name field from the item
          return rest; // Return the modified object without the name field
        });

        let postparam = {
          ...uploadPDF,
          ...uploadAttachment,
          ...customerRemark,
          // ...custData,
          // ...customerMemo,
          item: JSON.stringify(updatedItems),
          customer_address_id: chooseShippingAddress,
          billing_address_id: chooseBillingAddress,
          shipping_method_id: chooseShippingMethod,
          shipping_carrier_id: 0,
          total_amount: parseFloat(finalAmount).toFixed(2),
        };
        // console.log(postparam);
        checkout(postparam).then((json) => {
          if (stockAva.length > 0) {
            alert("Please Check Stock Available");
          } else {
            if (checkCurrency == "SGD") {
              if (json.status == true && json.jespa != "") {
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
                window.open(
                  "https://megahtex.com/paynow/" + json.jespa,
                  "_blank"
                );
              } else {
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
                setDisabled(false);
              }
            } else {
              if (json.status == false) {
                alert(json.message, json.data);
                setDisabled(false);
              }

              if (json.status == true && json.data != "" && json.razer == "") {
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
              }

              if (json.status == true && json.data != "" && json.razer != "") {
                window.open(json.razer);
                navigate("/orderhistorydetail/" + json.data);
                clearCart();
              }
            }
          }
        });
      }
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <React.Fragment>
      <Navbar />
      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#878787"} size={50} />
        </div>
      ) : (
        <Container>
          <Section pd="4rem 0 2rem 0">
            <Paragraph
              size="22px"
              color="#125396"
              bold="600"
              // center
              margin="0 0 2rem 0"
            >
              Order Summary
            </Paragraph>
            <TableContainer>
              <Table>
                <TableHead className={classes.theHead}>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                    {checkCurrency == "MYR" && (
                      <TableCell sx={{ textAlign: "center" }}>
                        Discount Per Pcs
                      </TableCell>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {newCartist != undefined && newCartist.length > 0
                      ? newCartist.map((item) => (
                        <>
                          <TableRow>
                            <TableCell>
                              <div
                                style={{ display: "flex" }}
                                className={classes.theWidth}
                              >
                                <div className={classes.theIMG}>
                                  <img src={item.image} />
                                </div>
                                <div style={{ marginLeft: "1rem" }}>
                                  <Paragraph
                                    margin="0 0 .2rem 0"
                                    bold="700"
                                    size="14px"
                                    className="_theWording"
                                    color={
                                      stockAva.includes(item.internalid)
                                        ? "red"
                                        : "#000"
                                    }
                                  >
                                    {item.name}
                                  </Paragraph>
                                  <Paragraph
                                    margin="0 0 .2rem 0"
                                    size="12px"
                                    className="_theWording"
                                  >
                                    {item.brand}
                                  </Paragraph>
                                  <Paragraph
                                    margin="0 0 .2rem 0"
                                    size="12px"
                                    className="_theWording"
                                  >
                                    {item.size}
                                  </Paragraph>
                                  <Paragraph
                                    margin="0 0 .2rem 0"
                                    size="12px"
                                    className="_theWording"
                                  >
                                    {item.color}
                                  </Paragraph>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={classes.theWidth2}>
                                {checkCurrency == "MYR" && (
                                  <Paragraph center>
                                    MYR{" "}
                                    {parseFloat(item.given_price).toFixed(2)}
                                  </Paragraph>
                                )}
                                {checkCurrency == "SGD" && (
                                  <Paragraph center>
                                    SGD {item.given_price}
                                  </Paragraph>
                                )}
                                {checkCurrency == "USD" && (
                                  <Paragraph center>
                                    USD {item.given_price}
                                  </Paragraph>
                                )}
                              </div>
                            </TableCell>
                            {checkCurrency == "MYR" && (
                              <TableCell>
                                <div className={classes.theWidth2}>
                                  <Paragraph center>
                                    MYR{" "}
                                    {parseFloat(item.given_discount).toFixed(
                                      2
                                    )}
                                  </Paragraph>
                                </div>
                              </TableCell>
                            )}
                            <TableCell>
                              <div>
                                <Paragraph
                                  style={{
                                    color: stockAva.includes(item.internalid)
                                      ? "red"
                                      : "#000",
                                  }}
                                  center
                                >
                                  {item.quantity}
                                </Paragraph>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={classes.theWidth2}>
                                {checkCurrency == "MYR" && (
                                  <Paragraph center>
                                    MYR{" "}
                                    {parseFloat(item.given_subtotal).toFixed(
                                      2
                                    )}
                                  </Paragraph>
                                )}
                                {checkCurrency == "SGD" && (
                                  <Paragraph center>
                                    SGD{" "}
                                    {parseFloat(item.given_subtotal).toFixed(
                                      2
                                    )}
                                  </Paragraph>
                                )}
                                {checkCurrency == "USD" && (
                                  <Paragraph center>
                                    USD{" "}
                                    {parseFloat(item.given_subtotal).toFixed(
                                      2
                                    )}
                                  </Paragraph>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                      : null}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
            <br />

            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox name="polybag" onChange={handleChange} checked={customerRemark.polybag} />} label="Require Polybag" />
                </FormGroup>

                <FormInputNoLabel
                  border="1px solid #d7d7d7"
                  placeholder="Remarks..."
                  name="customer_remark"
                  rows="2"
                  multiline
                  onChange={handleChange}
                />
                {/* <FormInputNoLabel
                  border="1px solid #d7d7d7"
                  placeholder="Memo..."
                  name="memo"
                  rows="2"
                  multiline
                  onChange={handleChange}
                /> */}
                <div
                  style={{
                    padding: "1rem",
                    borderRadius: "6px",
                    border: "1px solid #d7d7d7",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0 0 1rem 0",
                    }}
                  >
                    <Paragraph
                      bold="600"
                      color="#125396"
                      size="22px"
                    // margin="0 0 1rem 0"
                    >
                      Delivery Method
                    </Paragraph>
                  </div>
                  <>
                    {/* <Paragraph
                      bold="600"
                      margin="1rem 0 1rem 0"
                      style={{ fontStyle: "italic" }}
                    >
                      <span style={{ color: "#125396" }}>Contact Name</span>
                    </Paragraph>
                    <FormInputNoLabel
                      border="1px solid #d7d7d7"
                      placeholder="Contact Name"
                      name="contact_name"
                      onChange={handleChange}
                    />
                    <Paragraph
                      bold="600"
                      margin="1rem 0 1rem 0"
                      style={{ fontStyle: "italic" }}
                    >
                      <span style={{ color: "#125396" }}>Contact Number</span>
                    </Paragraph>
                    <FormInputNoLabel
                      border="1px solid #d7d7d7"
                      placeholder="Contact Number"
                      name="contact"
                      onChange={handleChange}
                      type="number"
                    /> */}

                    <Paragraph
                      bold="600"
                      margin="0 0 1rem 0"
                      style={{ fontStyle: "italic" }}
                      color="#125396"
                    >
                      Billing Address
                      {/* <span style={{ color: "#125396" }}>Billing Address</span> */}
                    </Paragraph>
                    <div>
                      {defaultBillingAddrList != [] && (
                        <>
                          <label className="theRadioAddress">
                            <input
                              type="radio"
                              name="radio"
                              value={chooseBillingAddress}
                              defaultChecked={
                                defaultBillingAddrList.defaultbilling == 1
                              }
                              checked={
                                chooseBillingAddress ==
                                defaultBillingAddrList.customer_address_id
                              }
                              onClick={() =>
                                handleChooseBillingAddress(
                                  defaultBillingAddrList.customer_address_id
                                )
                              }
                            />
                            <div className="checkmarkAddress">
                              <Paragraph
                                className="_theWording"
                                bold="600"
                                margin=" 0 0 .6rem 0"
                              >
                                {defaultBillingAddrList.entityid}
                              </Paragraph>
                              <Paragraph size="14px">
                                {defaultBillingAddrList.addr1},{" "}
                                {defaultBillingAddrList.addr2},{" "}
                                {defaultBillingAddrList.city},{" "}
                                {defaultBillingAddrList.zip},{" "}
                                {defaultBillingAddrList.state},{" "}
                                {defaultBillingAddrList.country}
                              </Paragraph>
                            </div>
                          </label>
                        </>
                      )}
                    </div>
                  </>

                  <>
                    <Paragraph
                      bold="600"
                      margin="1rem 0 1rem 0"
                      style={{ fontStyle: "italic" }}
                    >
                      Choose Your{" "}
                      <span style={{ color: "#125396" }}>Shipping Address</span>
                    </Paragraph>
                    <FormInputSelect>
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        name="customer_address_id"
                        value={chooseShippingAddress}
                        onChange={handleChooseShippingAddress}
                        style={{ marginBottom: "1rem" }}
                      >
                        <MenuItem value="" style={{ fontWeight: "400" }}>
                          <em style={{ color: "#878787" }}>
                            Please Choose a Shipping Method
                          </em>
                        </MenuItem>
                        {addressList.map((item) => (
                          <MenuItem
                            value={item.customer_address_id}
                            style={{ color: "#000" }}
                          >
                            {item.addr1}, {item.addr2}, {item.city}, {item.zip},{" "}
                            {item.state}, {item.country}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormInputSelect>
                  </>
                  <br />
                  <Button
                    label="ADD NEW ADDRESS"
                    color="#fff"
                    bg="#125396"
                    bold="600"
                    hover
                    // br="6px"
                    onClick={() => handleOpenModal()}
                  />
                </div>
                <br />
                {customerDropShip == 1 && (
                  <>
                    <Paragraph
                      bold="600"
                      color="#125396"
                      size="22px"
                      margin="0 0 1rem 0"
                    >
                      Upload Consignment Note
                    </Paragraph>
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={dropzone}
                    >
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    <>
                      {files == [] ? (
                        <div {...getRootProps({})}>
                          <input {...getInputProps()} />
                        </div>
                      ) : (
                        <div>{thumbs}</div>
                      )}
                    </>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {customerDropShip != 1 && (
                  <>
                    <div
                      style={{
                        padding: "1rem",
                        borderRadius: "6px",
                        border: "1px solid #d7d7d7",
                      }}
                    >
                      <Paragraph
                        bold="600"
                        color="#125396"
                        size="22px"
                        margin="0 0 1rem 0"
                      >
                        Upload Attachment
                      </Paragraph>
                      <>
                        <FormInputNoLabel
                          border="1px solid #d7d7d7"
                          placeholder="Upload Attachhment"
                          type="file"
                          accept=".jpg, .jpeg, pdf, .png"
                          onChange={handleChangeUpload2}
                        />
                      </>
                    </div>
                    <br />
                  </>
                )}
                <>
                  <div
                    style={{
                      padding: "1rem",
                      borderRadius: "6px",
                      border: "1px solid #d7d7d7",
                    }}
                  >
                    <div>
                      <Paragraph
                        size="22px"
                        color="#125396"
                        bold="600"
                        margin="0 0 1rem 0"
                      >
                        Shipping Method
                      </Paragraph>
                      <Paragraph
                        bold="600"
                        margin="0 0 1rem 0"
                        style={{ fontStyle: "italic" }}
                      >
                        Choose Your{" "}
                        <span style={{ color: "#125396" }}>
                          Shipping Method
                        </span>
                        <span style={{ color: "#F92000", marginLeft: "4px" }}>
                          *
                        </span>
                      </Paragraph>

                      <FormInputSelect>
                        <Select
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="shipping_method_id"
                          // value={customerDropShip == 1 ? 13 : chooseShippingMethod}
                          value={chooseShippingMethod}
                          // value={
                          //   shippingMethod.length > 0
                          //     ? shippingMethod[0].shipping_method_id
                          //     : ""
                          // }
                          onChange={handleChooseShippingMethod}
                          style={{ marginBottom: "1rem" }}
                        >
                          <MenuItem value="0" style={{ fontWeight: "400" }}>
                            <em style={{ color: "#878787" }}>
                              Please Choose a Shipping Method
                            </em>
                          </MenuItem>
                          {shippingMethod.map((item) => (
                            <MenuItem
                              value={item.shipping_method_id}
                              style={{ color: "#000" }}
                            >
                              {item.shippingmethod}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormInputSelect>
                      <br />

                      {chooseShippingMethod > 0 &&
                        shippingCarrier.length > 0 && (
                          <>
                            <Paragraph
                              bold="600"
                              margin="0 0 1rem 0"
                              style={{ fontStyle: "italic" }}
                            >
                              Choose Your{" "}
                              <span style={{ color: "#125396" }}>
                                Shipping Carrier
                              </span>
                              <span
                                style={{
                                  color: "#F92000",
                                  marginLeft: "4px",
                                }}
                              >
                                *
                              </span>
                            </Paragraph>
                            <FormInputSelect>
                              <Select
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name="shipping_carrier_id"
                                value={chooseCarrier}
                                onChange={handleChooseCarrier}
                                style={{ marginBottom: "1rem" }}
                              >
                                <MenuItem
                                  value=""
                                  style={{ fontWeight: "400" }}
                                >
                                  <em style={{ color: "#878787" }}>
                                    Please Choose a Shipping Carrier
                                  </em>
                                </MenuItem>
                                {shippingCarrier.map((item) => (
                                  <MenuItem
                                    value={item.shipping_carrier_id}
                                    style={{ color: "#000" }}
                                  >
                                    {item.shippingcarrier}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormInputSelect>
                          </>
                        )}

                      <br />
                    </div>
                  </div>
                </>
                <br />

                <div
                  style={{
                    padding: "1rem",
                    borderRadius: "6px",
                    border: "1px solid #d7d7d7",
                  }}
                >
                  <div>
                    <Paragraph
                      size="22px"
                      color="#125396"
                      bold="600"
                      margin="0 0 1rem 0"
                    >
                      Order Summary
                    </Paragraph>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <Paragraph size="1.2rem">Subtotal</Paragraph>
                      {checkCurrency == "MYR" && (
                        <Paragraph bold="500" size="1.2rem" center>
                          MYR {totalAmount}
                        </Paragraph>
                      )}
                      {checkCurrency == "SGD" && (
                        <Paragraph bold="500" size="1.2rem" center>
                          SGD {totalAmount}
                        </Paragraph>
                      )}
                      {checkCurrency == "USD" && (
                        <Paragraph bold="500" size="1.2rem" center>
                          USD {totalAmount}
                        </Paragraph>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Paragraph size="1.2rem">Discount Amount</Paragraph>
                      <>
                        {checkCurrency == "MYR" && (
                          <Paragraph bold="500" size="1.2rem">
                            MYR {discountAmount}
                          </Paragraph>
                        )}
                        {checkCurrency == "SGD" && (
                          <Paragraph bold="500" size="1.2rem">
                            SGD {discountAmount}
                          </Paragraph>
                        )}
                        {checkCurrency == "USD" && (
                          <Paragraph bold="500" size="1.2rem">
                            USD {discountAmount}
                          </Paragraph>
                        )}
                      </>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Paragraph size="1.2rem">Shipping Fee</Paragraph>

                      <>
                        {checkCurrency == "MYR" && (
                          <Paragraph bold="500" size="1.2rem">
                            MYR {shippingFee}
                          </Paragraph>
                        )}
                        {checkCurrency == "SGD" && (
                          <Paragraph bold="500" size="1.2rem">
                            SGD {shippingFee}
                          </Paragraph>
                        )}
                        {checkCurrency == "USD" && (
                          <Paragraph bold="500" size="1.2rem">
                            USD {shippingFee}
                          </Paragraph>
                        )}
                      </>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Paragraph size="1.2rem">Handling Fee</Paragraph>

                      <>
                        {checkCurrency == "MYR" && (
                          <Paragraph bold="500" size="1.2rem">
                            MYR {handlingFee}
                          </Paragraph>
                        )}
                        {checkCurrency == "SGD" && (
                          <Paragraph bold="500" size="1.2rem">
                            SGD {handlingFee}
                          </Paragraph>
                        )}
                        {checkCurrency == "USD" && (
                          <Paragraph bold="500" size="1.2rem">
                            USD {handlingFee}
                          </Paragraph>
                        )}
                      </>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <>
                        {checkCountry == 2 ? (
                          <>
                            {checkCurrency == "MYR" && (
                              <>
                                <Paragraph size="1.2rem">SST</Paragraph>
                                <Paragraph bold="500" size="1.2rem">
                                  MYR {sst}
                                </Paragraph>
                              </>
                            )}

                            {checkCurrency == "SGD" && (
                              <>
                                <Paragraph size="1.2rem">GST</Paragraph>
                                <Paragraph bold="500" size="1.2rem">
                                  SGD {gst}
                                </Paragraph>
                              </>
                            )}

                            {checkCurrency == "USD" && (
                              <>
                                <Paragraph size="1.2rem">SST</Paragraph>
                                <Paragraph bold="500" size="1.2rem">
                                  USD {sst}
                                </Paragraph>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {checkCurrency == "MYR" && (
                              <>
                                <Paragraph size="1.2rem">SST</Paragraph>
                                <Paragraph bold="500" size="1.2rem">
                                  MYR {sst}
                                </Paragraph>
                              </>
                            )}

                            {checkCurrency == "SGD" && (
                              <>
                                <Paragraph size="1.2rem">GST</Paragraph>
                                <Paragraph bold="500" size="1.2rem">
                                  SGD {gst}
                                </Paragraph>
                              </>
                            )}

                            {checkCurrency == "USD" && (
                              <>
                                <Paragraph size="1.2rem">SST</Paragraph>
                                <Paragraph bold="500" size="1.2rem">
                                  USD {sst}
                                </Paragraph>
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>

                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                        paddingTop: "1rem",
                        borderTop: "1px solid #d7d7d7",
                      }}
                    >
                      <Paragraph size="1.2rem">Total</Paragraph>

                      {checkCurrency == "MYR" && (
                        <>
                          <Paragraph color="#353535" bold="700" size="1.2rem">
                            MYR {finalAmount}
                          </Paragraph>
                        </>
                      )}

                      {checkCurrency == "SGD" && (
                        <>
                          <Paragraph color="#353535" bold="700" size="1.2rem">
                            SGD {finalAmount}
                          </Paragraph>
                        </>
                      )}

                      {checkCurrency == "USD" && (
                        <>
                          <Paragraph color="#353535" bold="700" size="1.2rem">
                            USD {finalAmount}
                          </Paragraph>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <br />

                <br />
                <div className={classes.theFlexx}>
                  <div className={classes.theRight}>
                    <Button
                      href="/cart"
                      label="Back to Cart"
                      br="4px"
                      color="#125396"
                      box="1px solid #125396"
                    />
                  </div>
                  <div>
                    {!disabled ? (
                      <Button
                        //   href="/checkout"
                        label="Proceed to Checkout"
                        br="4px"
                        color="#fff"
                        bg="#125396"
                        hover
                        onClick={() => handleCheckout()}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100px",
                          height: "35px",
                        }}
                      >
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Section>
        </Container>
      )}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#353535" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#125396">
              Add New Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Contact Name"
                  placeholder="Contact Name"
                  border="1px solid #d7d7d7"
                  name="contact_name"
                  onChange={handleChangeAddValid}
                  // maxLength={40} // Add maxLength attribute
                  required
                />
                <FormInput
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  border="1px solid #d7d7d7"
                  name="addr1"
                  onChange={handleChangeAddValid}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="addr2"
                  onChange={handleChangeAddValid}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                {/* <FormInput
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                  required
                /> */}
                <Paragraph margin="1rem 0">
                  State{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    name="name"
                    value={chooseState}
                    onChange={handleChooseState}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a State
                      </em>
                    </MenuItem>
                    {state.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Contact Number"
                  placeholder="Contact Number"
                  border="1px solid #d7d7d7"
                  name="contact_number"
                  onChange={handleChangeAddValid}
                  type="number"
                  // maxLength={40} // Add maxLength attribute
                  required
                />
                <Paragraph margin="1.5rem 0">
                  Country
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    name="name"
                    value={chooseCountry}
                    onChange={handleChooseCountry}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a Country
                      </em>
                    </MenuItem>
                    {country.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                  required
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <Paragraph>
                    Zip / Postal Code
                    <span style={{ color: "#F92000", marginLeft: "4px" }}>
                      *
                    </span>
                  </Paragraph>
                  {checkValidate == true ? (
                    <></>
                  ) : (
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#F90000",
                        fontSize: "14px",
                      }}
                    >
                      Please Enter Valid Zip / Postal Code
                    </span>
                  )}
                </div>
                <FormInputNoLabel
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  value={zipCode}
                  onChange={handleChangeZip}
                  required
                />
                {/* <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  // textLabelColor={checkValidate == false && "#F90000"}
                  // name="zip"
                  value={zipCode}
                  onChange={handleChangeZip}
                  required
                /> */}

                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={defaultBillingAddress}
                    />
                  }
                  label="Set As Default Billing Address"
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked2}
                      onChange={handleChangeCheck2}
                      value={defaultShippingAddress}
                    />
                  }
                  label="Set As Default Shipping Address"
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
