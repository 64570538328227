import React from "react";
// import Slider from "react-slick";
import useStyles, {
  FormInputSelect,
  IncrementAction,
  IncrementAction2,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import silestone001 from "../../assets/images/silestone001.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Pagination,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  CheckCircleOutline,
  Download,
  HighlightOff,
  Remove,
  Star,
} from "@mui/icons-material";
import "./styles.css";

import {
  get_area,
  get_brand,
  get_category,
  get_size,
  get_parent_item,
  get_gender,
  get_customer_profile,
  get_color_name,
} from "../../API/API";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

export default function Shop() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [sort, setSort] = React.useState(0);

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [profileData, setProfileData] = React.useState({});

  const [checkUserType, setCheckUserType] = React.useState("");

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
        setCheckUserType(json.data.paymenttype);
      } else {
        alert(json.message);
        window.localStorage.removeItem("token");
        navigate("/login");
      }
    });
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  const [category, setCategory] = React.useState([]);

  const [size, setSize] = React.useState([]);

  const [brand, setBrand] = React.useState([]);

  const [gender, setGender] = React.useState([]);

  const [color, setColor] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const getCategory = () => {
    get_category().then((json) => {
      setCategory(json.data);
    });
  };
  const getSize = () => {
    get_size({ token: token }).then((json) => {
      setSize(json.data);
    });
  };
  const getBrand = () => {
    get_brand({ token: token }).then((json) => {
      setBrand(json.data);
    });
  };
  const getGender = () => {
    get_gender({ token: token }).then((json) => {
      setGender(json.data);
    });
  };
  const getColor = () => {
    get_color_name({ token: token }).then((json) => {
      setColor(json.data);
    });
  };
  React.useEffect(() => {
    // getCategory();
    getSize();
    getBrand();
    getGender();
    getColor();
  }, []);

  // GET PRODUCT API

  const [product, setProduct] = React.useState([]);

  // const [chooseCategory, setChooseCategory] = React.useState(0);

  // const handleChooseCategory = (category_id) => {
  //   setChooseCategory(category_id);
  //   console.log(category_id);
  // };

  const [chooseBrand, setChooseBrand] = React.useState(0);

  const handleChooseBrand = (brand_id, e) => {
    setChooseBrand(brand_id);
    // if (brand_id === e.target.value) {
    //   // Check brand_id instead of e.target.value
    //   get_parent_item({
    //     brand_id: 0,
    //     gender_id: chooseGender,
    //     size_id: chooseSize,
    //     colorname: chooseColor,
    //     sort_by: sort,
    //     page: pageList,
    //   }).then((json) => {
    //     const bestBuyProducts = json.data.filter(
    //       (product) => product.bestbuy === "1"
    //     );
    //     setProduct(bestBuyProducts);
    //     setPageNum(json.pages);
    //     setLoading(false);
    //   });
    // }
  };

  const [chooseGender, setchooseGender] = React.useState(0);

  const handleChooseGender = (gender_id) => {
    setchooseGender(gender_id);
    // console.log(gender_id);
  };

  const [chooseSize, setChooseSize] = React.useState(0);

  const handleChooseSize = (size_id) => {
    setChooseSize(size_id);
    // console.log(size_id);
  };

  const [chooseColor, setChooseColor] = React.useState("");

  const handleChooseColor = (e) => {
    setChooseColor(e.target.value);
  };

  const handleChangeResetFilter = () => {
    setChooseBrand(0);
    setchooseGender(0);
    setChooseSize(0);
    setChooseColor("");
  };

  const getProduct = () => {
    setLoading(true);

    if (chooseBrand == "bestbuy") {
      get_parent_item({
        brand_id: 0,
        gender_id: chooseGender,
        size_id: chooseSize,
        colorname: chooseColor,
        sort_by: sort,
        page: pageList,
      }).then((json) => {
        const bestBuyProducts = json.data.filter(
          (product) => product.bestbuy === "1"
        );
        setProduct(bestBuyProducts);
        setPageNum(json.pages);
        setLoading(false);
      });
    } else {
      get_parent_item({
        brand_id: chooseBrand,
        gender_id: chooseGender,
        size_id: chooseSize,
        colorname: chooseColor,
        sort_by: sort,
        page: pageList,
      }).then((json) => {
        setProduct(json.data);
        setPageNum(json.pages);
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    getProduct();
  }, [chooseBrand, chooseGender, chooseSize, sort, chooseColor]);

  const [pageList, setPageList] = React.useState(1);

  const [pageNum, setPageNum] = React.useState("");

  const handleChangePage = (ispage, value) => {
    setLoading(true);
    setPageList(value);
    console.log(value);
    get_parent_item({
      brand_id: chooseBrand,
      gender_id: chooseGender,
      size_id: chooseSize,
      colorname: chooseColor,
      sort_by: sort,
      page: value,
    }).then((json) => {
      setProduct(json.data);
      setLoading(false);
    });
  };

  const handleNext = (item_id) => {
    if (token) {
      navigate("../shopdetail/" + item_id);
    } else {
      navigate("/login");
    }
  };

  // SEARCH

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  return (
    <React.Fragment>
      <Navbar />

      {/* <div className={classes.theBanner}>
        <img src={bannerproduct} />
        <div className={classes.theBox}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Paragraph
              className={classes.theFontTitle}
              color="#fff"
              bold="700"
              margin="0 1rem 0 0"
            >
              PRODUCT
            </Paragraph>
          </div>
        </div>
      </div> */}
      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#878787"} size={50} />
        </div>
      ) : (
        <>
          <div style={{ background: "#2a2866", padding: "4rem 1rem" }}>
            <div className={classes.theBoxx}>
              <Paragraph
                size="24px"
                color="#fff"
                bold="600"
                margin="0 0 .4rem 0"
                center
              >
                Shop
              </Paragraph>
              {/* <Paragraph size="14px" color="#fff" center>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                interdum eu libero non hendrerit. Nullam dapibus augue nec ante
                laoreet fringilla.
              </Paragraph> */}
              <br />
              <FormInputNoLabel
                placeholder="Search Color or Item Name..."
                // border="1px solid #acacac"
                bg="#FFF"
                onChange={handleSearch}
                value={searchKey}
              />
            </div>
          </div>
          <Container>
            <Section pd="2rem 0">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3}>
                  <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                    Filter By:
                  </Paragraph>
                  <Accordion
                    expanded={expanded === 1}
                    onChange={handleChange(1)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                      aria-controls="1"
                      id="1"
                    >
                      <Paragraph size="16px" bold="600" color="#353535">
                        Brand
                      </Paragraph>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={chooseBrand}
                        name="radio-buttons-group"
                      >
                        {brand.map((item) => (
                          <FormControlLabel
                            key={item.brand_id} // Assuming you have a unique key for each item
                            value={item.brand}
                            control={<Radio />}
                            label={item.brand}
                            onChange={(e) => handleChooseBrand(item.brand, e)} // Pass e here
                          />
                        ))}
                        <FormControlLabel
                          value="bestbuy"
                          control={<Radio />}
                          label="Best Buy"
                          onChange={(e) => handleChooseBrand("bestbuy", e)} // Pass e here
                        />
                      </RadioGroup>
                    </AccordionDetails>
                  </Accordion>
                  <hr />

                  <Accordion
                    expanded={expanded === 2}
                    onChange={handleChange(2)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                      aria-controls="2"
                      id="2"
                    >
                      <Paragraph size="16px" bold="600" color="#353535">
                        Gender
                      </Paragraph>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="Men"
                        value={chooseGender}
                        name="radio-buttons-group"
                      >
                        {/* <FormControlLabel
                      value="Men"
                      control={<Radio />}
                      label="Men"
                      onClick={() => handleChooseGender(1)}
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                      onClick={() => handleChooseGender(2)}
                    />
                    <FormControlLabel
                      value="Kid"
                      control={<Radio />}
                      label="Kid"
                      onClick={() => handleChooseGender(3)}
                    /> */}
                        {gender.map((item) => (
                          <FormControlLabel
                            value={item.name}
                            control={<Radio />}
                            label={item.name}
                            onChange={() => handleChooseGender(item.name)}
                          />
                        ))}
                      </RadioGroup>
                    </AccordionDetails>
                  </Accordion>
                  <hr />
                  <Accordion
                    expanded={expanded === 3}
                    onChange={handleChange(3)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                      aria-controls="3"
                      id="3"
                    >
                      <Paragraph size="16px" bold="600" color="#353535">
                        Size
                      </Paragraph>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="XS"
                        value={chooseSize}
                        name="radio-buttons-group"
                      >
                        {/* <FormControlLabel
                      value="XS"
                      control={<Radio />}
                      label="XS"
                      onClick={() => handleChooseSize(1)}
                    />
                    <FormControlLabel
                      value="S"
                      control={<Radio />}
                      label="S"
                      onClick={() => handleChooseSize(2)}
                    />
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="M"
                      onClick={() => handleChooseSize(3)}
                    />
                    <FormControlLabel
                      value="L"
                      control={<Radio />}
                      label="L"
                      onClick={() => handleChooseSize(4)}
                    />
                    <FormControlLabel
                      value="XL"
                      control={<Radio />}
                      label="XL"
                      onClick={() => handleChooseSize(5)}
                    />
                    <FormControlLabel
                      value="XXL"
                      control={<Radio />}
                      label="XXL"
                      onClick={() => handleChooseSize(6)}
                    /> */}
                        {size.map((item) => (
                          <FormControlLabel
                            value={item.name}
                            control={<Radio />}
                            label={item.name}
                            onChange={() => handleChooseSize(item.name)}
                          />
                        ))}
                      </RadioGroup>
                    </AccordionDetails>
                  </Accordion>
                  <hr />
                  <Accordion
                    expanded={expanded === 4}
                    onChange={handleChange(4)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                      aria-controls="3"
                      id="3"
                    >
                      <Paragraph size="16px" bold="600" color="#353535">
                        Color
                      </Paragraph>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="XS"
                        value={chooseColor}
                        name="radio-buttons-group"
                      >
                        {color.map((item) => (
                          <FormControlLabel
                            value={item.colorname}
                            control={<Radio />}
                            label={item.colorname}
                            onChange={() => handleChooseColor(item.colorname)}
                          />
                        ))}
                      </RadioGroup> */}
                      <FormInputSelect>
                        <Select
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={chooseColor}
                          onChange={handleChooseColor}
                          style={{ marginBottom: "1rem" }}
                        >
                          <MenuItem value="" style={{ fontWeight: "400" }}>
                            <em style={{ color: "#878787" }}>
                              Please Choose a Color
                            </em>
                          </MenuItem>
                          {color.map((item) => (
                            <MenuItem
                              value={item.colorname}
                              style={{ color: "#000" }}
                            >
                              {item.colorname}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormInputSelect>
                    </AccordionDetails>
                  </Accordion>
                  <hr />
                  <br />
                  <Button
                    label="Reset Filter"
                    color="#FFF"
                    bg="#125396"
                    hover
                    onClick={() => handleChangeResetFilter()}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={classes.theWidth}>
                      <Paragraph
                        color="#353535"
                        bold="500"
                        className={classes.theWidth01}
                      >
                        Sort By:
                      </Paragraph>
                      <FormInputSelect className={classes.theWidth02}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sort}
                          defaultValue={sort}
                          onChange={handleChangeSort}
                        >
                          <MenuItem value="0" style={{ color: "#000" }}>
                            Latest to Oldest
                          </MenuItem>
                          <MenuItem value="1" style={{ color: "#000" }}>
                            Oldest to Latest
                          </MenuItem>
                        </Select>
                      </FormInputSelect>
                    </div>
                  </div>
                  <br />
                  <Grid container spacing={3}>
                    {product != null &&
                      product.map((item, index) => {
                        if (searchKey != "") {
                          var colorList = [];

                          for (var i = 0; i < item.color.length; i++) {
                            let a = item.color[i];
                            colorList.push(a.colorname.toUpperCase());
                          }
                          // console.log(colorlist)
                          // console.log("asd", colorlist.indexOf("NAVY"));
                          if (
                            item.displayname
                              .toUpperCase()
                              .indexOf(searchKey.toUpperCase()) <= -1 &&
                            colorList.indexOf(searchKey.toUpperCase()) <= -1 &&
                            item.itemname
                              .toUpperCase()
                              .indexOf(searchKey.toUpperCase()) <= -1
                          ) {
                            return;
                          }
                        }
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <a onClick={() => handleNext(item.item_id)}>
                              <div className={classes.theGallery}>
                                <img src={item.image.image} />
                                {/* <div className={classes.theTag}>
                              <Paragraph color="#FFF" bold="600">
                                NEW
                              </Paragraph>
                            </div> */}
                              </div>
                              <Paragraph color="#333335" size="14px" bold="100">
                                {/* {item.cseg_boc_brand} */}
                                {item.brand}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                margin="0 0 .6rem 0"
                              >
                                {item.displayname}
                              </Paragraph>
                            </a>
                          </Grid>
                        );
                      })}
                    {/* <Grid item xs={12} sm={6} md={4}>
                  <a href="/custommadedetail">
                    <Card>
                      <div className={classes.theGallery}>
                        <img src={silestone001} />
                        <div className={classes.theTag}>
                          <Paragraph color="#FFF" bold="600">
                            NEW
                          </Paragraph>
                        </div>
                      </div>
                      <div style={{ padding: ".8rem 1rem" }}>
                        <Paragraph
                          color="#333335"
                          size="12px"
                          bold="400"
                          margin="0 0 .3rem 0"
                        >
                          BARND A
                        </Paragraph>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Paragraph size="14px" color="#333335" bold="600">
                            PRODUCT NAME
                          </Paragraph>
                          <Paragraph size="14px" color="#333335" bold="600">
                            RM 0000.00
                          </Paragraph>
                        </div>
                        <br />
                        <div style={{ display: "flex" }}>
                          <Paragraph margin="0 .4rem 0 0">XS</Paragraph>
                          <Paragraph margin="0 .4rem 0 0">S</Paragraph>
                          <Paragraph margin="0 .4rem 0 0">M</Paragraph>
                          <Paragraph margin="0 .4rem 0 0">L</Paragraph>
                          <Paragraph margin="0 .4rem 0 0">XL</Paragraph>
                          <Paragraph margin="0 .4rem 0 0">XXL</Paragraph>
                        </div>
                      </div>
                    </Card>
                  </a>
                </Grid> */}
                  </Grid>
                  <br />
                  <br />
                  <Stack
                    spacing={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Pagination
                      page={pageList}
                      onChange={handleChangePage}
                      count={pageNum}
                      defaultPage={pageList}
                      // count={10}
                    /> */}
                  </Stack>
                </Grid>
              </Grid>
            </Section>
          </Container>
        </>
      )}

      <Footer />
    </React.Fragment>
  );
}
